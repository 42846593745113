import "./index.css";
import "./App.css";
import Icon from "./icon.png";
import LinkedInIcon from "./linkedin-icon.png";
import GitHubIcon from "./github-icon.png";
import MailIcon from "./mail-icon.png";
import { useState } from "react";

function HeaderTopics({ title, link }) {
	return (
		<p className="headerContent headerContentHover">{title}</p>
	);
}

function Header() {
	const [displayTopics, setDisplayTopics] = useState(false);

	function handleMenuToggle() {
		setDisplayTopics(!displayTopics)
	}

	return (
		<div className="headerStyle">
			<div className="headerLeftStyle">
				<img className="headerIcon" src={ Icon }></img>
				<p className="headerContent">Gabriel Servais</p>
			</div>
			<div className="headerRightStyle">
				{ displayTopics
					? <>
					  	<HeaderTopics title="About Me" link="" />
				      	<HeaderTopics title="Blog" link="" />
				      	<HeaderTopics title="Projects" link="" />
						<p className="headerContent headerContentHover" onClick={() => handleMenuToggle()}>&gt;</p>
					  </>
					: <p className="headerContent headerContentHover" onClick={() => handleMenuToggle()}>&lt;</p>
				}
			</div>
		</div>
	);
}

function CircleActions({ title, onActionClick }) {
	return (
		<a className="bodyActionStyle" onClick={onActionClick}>
			<div class="bodyActionCircle"></div>	
			<p className="bodyActionText">{title}</p>
		</a>
	);
}

function SocialMediaSideBar({ title, link, icon }) {
	return (
		<a className="bodySocialStyle" href={link} target="_blank">
			<p className="bodySocialText">{title}</p>
			<img className="bodySocialIcon" src={icon}></img>
		</a>
	);
}

function Body({ handleAppStyle }, prefersDarkMode) {
	const [displayWeather, setDisplayWeather] = useState(false);

	const [isDarkMode, setIsDarkMode] = useState(prefersDarkMode);

	function handleActionClick(i) {
		if (i === 1) {
			setDisplayWeather(!displayWeather);
		} else if (i === 2) {
			handleAppStyle(1);		
		} else if (i === 3) {
			setIsDarkMode(!isDarkMode);
			handleAppStyle(2);
		}
	}

	return (
		<div className="bodyStyle">
			<div className="bodyLeft">
				{ displayWeather
					? <CircleActions title="Hide Weather" onActionClick={() => handleActionClick(1)}/>
					: <CircleActions title="Display Weather" onActionClick={() => handleActionClick(1)}/>
				}
				<CircleActions title="Change Background" onActionClick={() => handleActionClick(2)}/>
				{ isDarkMode
					? <CircleActions title="Light Mode" onActionClick={() => handleActionClick(3)}/>
					: <CircleActions title="Dark Mode" onActionClick={() => handleActionClick(3)}/>
				}
			</div>
			{ displayWeather
				? <Weather />
				: null
			}
			<div className="bodyRight">
				<SocialMediaSideBar title="GitHub" link="https://github.com/gpjservais" icon={ GitHubIcon } /> 
				<SocialMediaSideBar title="LinkedIn" link="https://www.linkedin.com/in/gabriel-servais" icon={ LinkedInIcon } />
				<SocialMediaSideBar title="Email" link="" icon={ MailIcon } />
			</div>
		</div>
	);
}

function Weather() {
	const [currentTime, setCurrentTime] = useState(new Date());

	const [location, setLocation] = useState({
		latitude: null,
		longitude: null,
	});

	return (
		<div className="bodyCenter">
			<div className="bodyCenterStyle">
				<p className="bodyCenterText">Current time: {currentTime.toLocaleTimeString()}</p>
				<p className="bodyCenterText">Your current position is:</p>
				<p className="bodyCenterText">(Latitude: {location.latitude}, Longitude: {location.longitude})</p>
			</div>
		</div>
	)
}

function FooterLinks({ title, onFooterClick }) {
	return (
		<p className="footerContent footerContentHover" onClick={onFooterClick}>{title}</p>
	);
}

function Footer() {
	const [displayCredits, setDisplayCredits] = useState(false);

	function handleFooterClick(i) {
		if (i === 1) {
			setDisplayCredits(!displayCredits);
		}
	}

	return (
		<>
			<div className="footerStyle">
				<FooterLinks title="Site Info..." onFooterClick={() => handleFooterClick(0)}/>
				<FooterLinks title="Credits..." onFooterClick={() => handleFooterClick(1)}/>
			</div>
			{ displayCredits
				? <Credits onExitSideBar={() => handleFooterClick(1)}/>
				: null
			}
		</>
	);
}

function CreditReference({ title, subtitle, link }) {
	return (
		<a className="RightSideBarText" href={link} target="_blank">
			<h3>{title}</h3>
			<p>{subtitle}</p>
		</a>
	);
}

function Credits({ onExitSideBar }) {
	return (
		<div className="RightSideBarStyle">
			<p className="RightSideBarText" onClick={onExitSideBar}>Back</p>
			<p><br/></p>
			<CreditReference title="Two Men Contemplating the Moon" subtitle="Caspar David Friedrich" link="https://www.metmuseum.org/art/collection/search/438417" />
			<p><br/></p>
			<CreditReference title="At the Waterfall" subtitle="David Claypoole Johnston" link="https://www.metmuseum.org/art/collection/search/11270" />
		</div>
	);
}

export default function App() {
	const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

	const [background, setBackground] = useState({
		image: prefersDarkMode ? "image1" : "image2",
		darkMode: prefersDarkMode ? "darkBackground" : "lightBackground",
	})

	function handleAppStyleUpdate(i) {
		if (i === 1) {
			if (background.image === "image1") {
				setBackground({
					image: "image2",
					darkMode: background.darkMode,
				});
			} else {
				setBackground({
					image: "image1",
					darkMode: background.darkMode,
				});
			}
		} else if (i === 2) {
			if (background.darkMode === "darkBackground") {
				setBackground({
					image: background.image,
					darkMode: "lightBackground",
				});
			} else {
				setBackground({
					image: background.image,
					darkMode: "darkBackground",
				});
			}
		}
	}

	return (
		<div className={`appStyle ${background.darkMode} ${background.image}`}>
			<Header />
			<Body handleAppStyle={(i) => handleAppStyleUpdate(i)} prefersDarkMode={prefersDarkMode} />
			<Footer />
		</div>
	);
}
